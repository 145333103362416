import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Modal from 'react-modal';
import ContactFormComponent from '../contactform/form';
import close from './../../assets/img/close_button.svg';
import './styles.scss';

export default function ContactModal({open=false, closeFn}){
    const {t}=useTranslation('ContactModal');

    useEffect(() => {
        if (typeof(window) !== 'undefined') {
            Modal.setAppElement('#root');
        }
    }, [])

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeFn}
            className="ContactModal"
        >
            <div className="ContactModal__content">
                <h2 className="title--1">
                    <Trans t={t} i18nKey="title">
                        Platiquemos<br/> sin que te cueste_
                    </Trans>
                </h2>
                <p className="margin-text">
                    <Trans t={t} i18nKey="description">
                        Te regalamos una hora como cortesía para que nos conozcas, conozcas nuestro portafolio y hablemos sobre tus ideas.
                    </Trans>
                </p>
                <img src={close} className="ContactModal__close" alt="Cerrar" onClick={closeFn} />
                <ContactFormComponent buttonColor="blue" formColor="black" />
            </div>
            <div className="ContactModal__illustration">
                <img src="popup_illustration.svg" alt="" />                    
            </div>
        </Modal>
    );
}