import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import invex from '../../assets/img/invex@3x.png';
import azteca from '../../assets/img/baz@3x.png';
import scotiabank from '../../assets/img/scotiabank@3x.png';
import italika from '../../assets/img/italika@3x.png';
import elektra from '../../assets/img/elektra@3x.png';
import sura from '../../assets/img/sura@3x.png';

import './styles.scss';


export default function PastClients() {
    const { t } = useTranslation('PastClients');
    return (
    <section className="container PastClients">
        <h2 className="title--2"><Trans t={t} i18nKey="label">Ellos confiaron en nosotros_</Trans></h2>
        <div className="brands">
            <img width="175" height="31" className="brands__item invex" alt="Invex" src={invex} />
            <img width="175" height="31" className="brands__item azteca" alt="Banco Azteca" src={azteca} />
            <img width="175" height="31" className="brands__item scotia" alt="Scotiabank" src={scotiabank} />
            <img width="175" height="31" className="brands__item italika" alt="Italika" src={italika} />
            <img width="175" height="31" className="brands__item elektra" alt="Elektra" src={elektra} />
            <img width="175" height="31" className="brands__item sura" alt="Sura" src={sura} />
        </div>
    </section>
  );
}