import React from 'react';
import PastClients from './components/pastclients';
import Header from './components/header';
import Footer from './components/footer';
import ParallaxAgile from './components/parallaxagile';
import ContactForm from './components/contactform';
import Experiences from './components/experiences';
import HeroSlider from './components/heroslider';
import Allies from './components/allies';
import Software from './components/software';
import FixedTools from './components/fixedtools';


function App() {
  return(<>
  <Header/>
  <HeroSlider />
  <Allies />
  <PastClients />
  <Software />
  <ParallaxAgile />
  <Experiences />
  <ContactForm />
  <Footer />
  <FixedTools />
  </>)
}
export default App;
