import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import mockupsSmall from '../../assets/img/mockups.png';
import mockupsMedium from '../../assets/img/mockups@2x.png';
import mockupsLarge from '../../assets/img/mockups@3x.png';
import './styles.scss';

export default function Software(){
    const {t} = useTranslation('Software');
    return (
        <section className="Software container cols">
            <div className="col">
                <h2 className="title--1"><Trans t={t} i18nKey="title">Desarrollamos software con enfoque_</Trans></h2>
                <p className="margin-text">
                    <Trans t={t} i18nKey="text">
                    Somos una fábrica de software que genera soluciones tecnológicas alineadas a las necesidades del negocio y del mercado con visión, creando desde el inicio una arquitectura de sistemas sólida, modular y escalable.
                    </Trans>
                </p>
                <ul className="list margin-text" >
                    <Trans t={t} i18nKey="list">
                        <li>* Arquitectura de sistemas</li>
                        <li>* Bases de datos</li>
                        <li>* Aplicaciones Web & Móviles</li>
                        <li>* VR & Robots</li>
                        <li>* Sitios Web & e-commerce</li>
                        <li>* Testing automatizados</li>
                    </Trans>
                </ul>
                <button className="blue Software__button" type="button"><Trans t={t} i18nKey="cta">Conoce nuestro portafolio</Trans></button>
            </div>
            <div className="col">
                <div className="Software__illustration" >
                    <img width="498" height="627" alt="" src={mockupsSmall} srcSet={`${mockupsSmall} 1x, ${mockupsMedium} 2x, ${mockupsLarge} 3x`}/>
                </div>
            </div>
        </section>
    )
}