import * as Yup from 'yup';

const ContactFormValidations = Yup.object().shape({
    name: Yup.string()
     .min(2, 'Escribe un nombre válido')
     .required('Requerido'),
    email: Yup.string().email('Escribe un correo válido').required('Requerido'),
    date: Yup.string().required('Requerido')
})

export default ContactFormValidations;