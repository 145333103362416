import React, { useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import whatsapp from '../../assets/img/whatsapp.svg';
import { scrollTo } from '../../utils/utils';
import './styles.scss';

const FixedTools = () => {
  
    const scrollTop = () => {
        console.log('click');
        scrollTo(0, 500)
    }
  
    useEffect(() => {
        const onScroll = e => {
          const showButton = e.target.documentElement.scrollTop;
          if(!showButton){
            document.querySelector('.FixedTools__upbutton').classList.add('hide');
          } else {
            document.querySelector('.FixedTools__upbutton').classList.remove('hide');
          }
        };
        window.addEventListener("scroll", onScroll);
      }, []);

    return (<section className="FixedTools">
        <div onClick={scrollTop} className="FixedTools__upbutton hide">
            <FaChevronUp size="2em" />
        </div>
        <a className="FixedTools__whatsapp" href="https://wa.me/5215543545543">
            <img width="54" height="54"  alt="Envía un mensaje por WhatsApp" src={whatsapp} />
        </a>
    </section>);
}
    

export default FixedTools;