import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import  DatePicker, { registerLocale}  from 'react-datepicker';
import { format, setHours, setMinutes } from 'date-fns';
import es from "date-fns/locale/es"
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import validations from './validations';
import { init, send } from 'emailjs-com';

init("user_Vom2pRY7zPjWjzGxEv3AR");
registerLocale("es", es);

export default function ContactFormComponent({buttonColor, formColor}){

    const postForm = (values, {resetForm}) => {
        values.date = format(values.date, 'dd/MM/yyyy hh:mm:ss a');
        send("service_z5bj2hb",`${process.env.REACT_APP_EMAIL_TEMPLATE}`, values).then(() => {
            resetForm();
        }).catch(() => {
            alert('Error al enviar, inténtalo de nuevo')
        });
    }

    const {t} = useTranslation('ContactForm');

    const [startDate, setStartDate] = useState(new Date());

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            date: startDate,
        },
        validationSchema: validations,
        onSubmit: postForm,
    });
    
    return(
        
        <div className={`ContactForm__form ${formColor}`}>
            <form id="contactform" onSubmit={formik.handleSubmit}>
                <h3 className="title--2 margin-text">
                <Trans t={t} i18nKey="subtitle">Agenda una videollamada</Trans></h3>
                <div className="ContactForm__form__inputcontainer">
                    <input 
                        maxLength="50" 
                        name="name" 
                        value={formik.values.name} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur} 
                        placeholder={t('name', {
                            defaultValue:  'Tu nombre*'
                        })} 
                    />
                        {formik.errors.name && formik.touched.name ? <span className="ContactForm__form__inputerror">{formik.errors.name}</span> : null}
                </div>
                <div className="ContactForm__form__inputcontainer">
                    <input 
                        maxLength="100" 
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={t('email',{
                            defaultValue:  'Correo electrónico*'
                        })} 
                    />
                    {formik.errors.email && formik.touched.email ? <span className="ContactForm__form__inputerror">{formik.errors.email}</span> : null}
                </div>
                <div className="ContactForm__form__inputcontainer">
                    <DatePicker
                        selected={startDate}
                        onChange={date =>{setStartDate(date); formik.setFieldValue('date', date, true); formik.setFieldTouched('date')}} 
                        showTimeSelect
                        wrapperClassName="datepicker" 
                        dateFormat="dd/MM/yyyy hh:mm:ss a"
                        locale="es"
                        timeCaption="Hora"
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 0), 18)}
                        minDate={new Date()}
                        customInput={<input
                            name="date" 
                            value={formik.values.date} 
                            placeholder={t('date',{
                                defaultValue:  'Fecha y hora*'
                            })} 
                        />}
                    />
                    
                    {formik.errors.date && formik.touched.date ? <span className="ContactForm__form__inputerror">{formik.errors.date}</span> : null}
                </div>
                <button type="submit" className={buttonColor || 'white'}>
                    <Trans t={t} i18nKey="submit">Agendar ahora</Trans>
                </button>
            </form>
            
        </div>
    )
}