import React, { useEffect, useState } from 'react';
import { Trans, useTranslation  } from 'react-i18next';
import slide1 from '../../assets/img/slide1.png';
import slide2 from '../../assets/img/slide2.png';
import slide3 from '../../assets/img/slide1.png';
import ContactModal from '../contactmodal';
import './styles.scss';

function HeroSlider(){

    const { t } = useTranslation('HeroSlider');

    const [active, setActive] = useState(0);

    useEffect(() => {

        let currentSlide = 0;

        setInterval(() => {

            currentSlide++;

            if(currentSlide >= 3){
                currentSlide = 0;
            }

            setActive(currentSlide);

        }, 5000)
    
    }, []);

    const [modalIsOpen,setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function closeModal(){
        setIsOpen(false);
    }

    return (
        <>
            <section className={`container HeroSlider ${active === 1 ? 'blue' : ''}`}>
                <div className={`HeroSlider__slide ${active === 0 ? 'active' : ''}`} style={{backgroundImage: `url(${slide1})`}}>
                    <div className={`container HeroSlider__slide__text `}>
                        <h2 className="title--1"><Trans t={t} i18nKey={'slide1'}><span>Llevamos tus ideas</span> al siguiente nivel_</Trans></h2>
                        <button type="button" onClick={openModal}><Trans t={t} i18nKey={'ctaslide1'}>Cotiza tu idea</Trans></button>
                    </div>
                </div>
                <div className={`HeroSlider__slide blue ${active === 1 ? 'active' : ''}`} style={{backgroundImage: `url(${slide2})`}}>    
                    <div className={`container HeroSlider__slide__text `}>
                        <h2 className="title--1"><Trans t={t} i18nKey={'slide2'}><span>La clave es no limitarte,</span> piensa al extremo_</Trans></h2>
                        <button type="button" onClick={openModal}><Trans t={t} i18nKey={'ctaslide2'}>Cotiza tu idea</Trans></button>
                    </div>
                </div>
                <div className={`HeroSlider__slide ${active === 2 ? 'active' : ''}`} style={{backgroundImage: `url(${slide3})`}}>   
                    <div className={`container HeroSlider__slide__text `}>
                        <h2 className="title--1"><Trans t={t} i18nKey={'slide3'}><span>Desarrollamos software</span> con enfoque_</Trans></h2>
                        <button type="button" onClick={openModal}><Trans t={t} i18nKey={'ctaslide3'}>Cotiza tu idea</Trans></button>
                    </div>
                </div>

                <div className="HeroSlider__indicator">
                    <div onClick={() => {setActive(0)}} className={`HeroSlider__indicator__item ${active === 0 ? 'active' : ''}`}></div>
                    <div onClick={() => {setActive(1)}} className={`HeroSlider__indicator__item ${active === 1 ? 'active' : ''}`}></div>
                    <div onClick={() => {setActive(2)}} className={`HeroSlider__indicator__item ${active === 2 ? 'active' : ''}`}></div>
                </div>
            </section>
            {
                modalIsOpen ? <ContactModal open={modalIsOpen} closeFn={closeModal} /> : ''
            }
        </>
    )
}

export default HeroSlider;