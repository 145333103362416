import React, { useState } from 'react';
import i18n from '../../i18n';
import logoSmall from '../../assets/img/logo.png';
import logoMedium from '../../assets/img/logo@2x.png';
import logoLarge from '../../assets/img/logo@3x.png';
import logoDarkSmall from '../../assets/img/logo-dark.png';
import logoDarkMedium from '../../assets/img/logo-dark@2x.png';
import logoDarkLarge from '../../assets/img/logo-dark@3x.png';
import themeLight from '../../assets/img/sun@3x.png';
import themeDark from '../../assets/img/moon@3x.png';
import globe from '../../assets/img/globe@3x.png';

import './styles.scss';

export default function Header () {

    const [theme, setTheme] = useState('light');
    const [lang, setLang] = useState('es');

    const toggleLang = (selected) => {
        setLang(selected);
        i18n.changeLanguage(selected)
    }  
    
    const toggleTheme = () => {
        setTheme((theme === 'light' ? 'dark' : 'light'));
        setTimeout(() => {
            document.getElementsByTagName('body')[0].setAttribute('class',( theme === 'light' ? 'dark' : 'light'));
        }, 10)
    }
    return (
        <section className="Header">
            <nav className="container ">
                {
                    theme === 'light' ? 
                    <img width="188" height="60" className="Header__logo" alt="Qcode" src={logoSmall} srcSet={`${logoSmall} 1x, ${logoMedium} 2x, ${logoLarge} 3x`} /> :
                    <img width="188" height="60" className="Header__logo" alt="Qcode" src={logoDarkSmall} srcSet={`${logoDarkSmall} 1x, ${logoDarkMedium} 2x, ${logoDarkLarge} 3x`} />
                }
                <div className="Header__controls">
                    <div className="Header__controls__language title--2">
                    <span onClick={() => toggleLang('es')} className={`Header__controls__language__option ${lang === 'es' ? 'active':''}`}>es</span> | <span onClick={() => toggleLang('en')} className={`Header__controls__language__option ${lang === 'en' ? 'active':''}`}>en</span>
                        <img 
                            className="Header__controls__language__icon"
                            alt=""
                            src={globe} />
                    </div>
                    <div className="Header__controls__theme">
                        <img 
                            className="Header__controls__theme__click"
                            onClick={toggleTheme}
                            alt={`${theme === 'light' ? 'Activar':'Desactivar'} modo oscuro`} 
                            src={theme === 'light' ? themeDark : themeLight} />
                    </div>
                </div>
            </nav>
        </section>
        
    )
}