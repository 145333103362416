import React from 'react';
import rocket from '../../assets/img/rocket@3x.png';
import loop from '../../assets/img/loop@3x.png';
import growth from '../../assets/img/growth@3x.png';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';

export default function ParallaxAgile() {
    const {t} = useTranslation('ParallaxAgile');
    return(
        <section className=" ParallaxAgile">
            <video playsInline autoPlay muted loop id="bgvid">
                <source src="video.webm" type="video/webm" />
                <source src="video.mp4" type="video/mp4" />
            </video>
            <div className="ParallaxAgile__content container">
                <h2 className="title--1">
                    <Trans t={t} i18nKey="title">
                        Nos adaptamos al ritmo de tu organización_
                    </Trans>
                </h2>
                <p className="ParallaxAgile__text"><Trans t={t} i18nKey="text">Somos resilientes, nos alineamos al sentido de urgencia de tus proyectos y el de tu empresa. Nos apegamos a una metodología de trabajo ágil, rodeado de un equipo de trabajo colaborativo para realizar entregables de calidad que puedan validarse rápido. </Trans></p>
                <div className="ParallaxAgile__tenets">
                    <div className="ParallaxAgile__tenets__item">
                        <img width="72" height="73" src={rocket} alt="" />
                        <p className="title--2"><Trans t={t} i18nKey="item1">Visión oportuna, <br />escalable & segura</Trans></p>
                    </div>
                    <div className="ParallaxAgile__tenets__item loop-item">
                        <img width="86" height="36" src={loop} className="loop" alt="" />
                        <p className="title--2"><Trans t={t} i18nKey="item2">Entregas tempranas &<br />iteración constante</Trans></p>
                    </div>
                    <div className="ParallaxAgile__tenets__item">
                        <img width="72" height="73" src={growth} alt="" />
                        <p className="title--2"><Trans t={t} i18nKey="item3">Optimización de<br />recursos</Trans></p>
                    </div>
                </div>
            </div>
        </section>
    )
}