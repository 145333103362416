import React from 'react';
import { Trans, useTranslation  } from 'react-i18next';
import teamSmall from '../../assets/img/team.png';
import teamMedium from '../../assets/img/team@2x.png';
import teamLarge from '../../assets/img/team@3x.png';
import './styles.scss';

export default function Allies(){
    const { t } = useTranslation('Allies');
    return (
        <section className="Allies container cols">
            <div className="col Allies__illustration">
                <img style={{width: '100%'}} alt="" src={teamSmall} srcSet={`${teamSmall} 1x, ${teamMedium} 2x, ${teamLarge} 3x`}/>
            </div>
            <div className="col">
                <h2 className="title--1"><Trans t={t} i18nKey={'title'}>Somos tu aliado tecnológico ideal_</Trans></h2>
                <p className="margin-text"><Trans t={t} i18nKey={'text'}><span>Te acompañamos desde el kick-off hasta la implementación de tu proyecto. </span><span>Desarrollamos productos digitales alineados a los más altos estándares de calidad, utilizando herramientas de última generación y con mayor demanda en el mercado.</span></Trans></p>
            </div>
        </section>
    )
}
