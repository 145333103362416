import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './styles.scss';

export default function Footer() {
    const {t} = useTranslation('Footer');
    return(
        <section className="Footer container">
            <h4 className="Footer__title">“Quality not quantity”_</h4>
            <p className="Footer__info">
                <span className="Footer__info__text">
                    <Trans t={t} i18nKey="label">Visítanos en</Trans> WTC Ciudad de México<br/>
                    Montecito 38 Piso 28 Oficina 16, Col. Nápoles, 03810 Benito Juárez<br/>
                    <Trans t={t} i18nKey="phone">Teléfono</Trans> / WhatsApp <a href="tel:+52 55 4354 5543">+52 55 4354 5543</a>
                </span>
            </p>
        </section>
    )
}