import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import videoChatSmall from '../../assets/img/videochat.png';
import videoChatMedium from '../../assets/img/videochat@2x.png';
import videoChatLarge from '../../assets/img/videochat@3x.png';
import './styles.scss';
import ContactFormComponent from './form';  

export default function ContactForm(){

    const {t}=useTranslation('ContactForm');
    
    return(
        <section className="container cols ContactForm">
            <div className="col">
                <div className="ContactForm__text">
                    <h2 className="title--1">
                        <Trans t={t} i18nKey="title">
                        ¡Somos tu equipo!<br/> Estamos donde tu estés_
                        </Trans>
                    </h2>
                    <p>
                    <Trans t={t} i18nKey="description">
                    Firmemos un NDA antes de platicar; por el costo ni te preocupes, te regalamos una hora como cortesía para que nos conozcas, hablemos sobre tus ideas y despejes todas tus inquietudes.
                    </Trans>
                    </p>
                </div>
                <div className="ContactForm__ilustration ContactForm__ilustration--mobile margin-text">
                    <img style={{width: '100%'}} alt="" src={videoChatSmall} srcSet={`${videoChatSmall} 1x, ${videoChatMedium} 2x, ${videoChatLarge} 3x`}/>
                </div>
                <ContactFormComponent />
            </div>
            <div className="col ContactForm__ilustration--desktop">
                <div className="ContactForm__ilustration margin-text">
                    <img style={{width: '100%', height: 'auto'}} width="558" height="535" alt="" src={videoChatSmall} srcSet={`${videoChatSmall} 1x, ${videoChatMedium} 2x, ${videoChatLarge} 3x`}/>
                </div>
            </div>
        </section>
    )
}